<template>
  <div class="main">
  <div class="container">
    <van-nav-bar
      title="东京华人圈"
    />
    <!-- 密码输入框 -->
    <van-password-input
      :value="value"
      :mask="false"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
    />
    <!-- 数字键盘 -->
    <van-number-keyboard
      v-model="value"
      :show="showKeyboard"
      :maxlength="6"
      @blur="showKeyboard = false"
    />
    <div style="margin: 30px 16px;">
      <van-button round block type="info" native-type="submit" @click="goDetail">提交</van-button>
    </div>
    <ul>
      <div v-if="false">
        <li  v-for="(item,index) in this.list" :key="index">
          
            {{item}}
          
        </li>
        </div>
    </ul>
  </div>
  <DjhrqFooter/>
  </div>
</template>

<script>
import DjhrqFooter from "@/components/footer"
export default {
  name: 'DjhrqList',
    components:{
    DjhrqFooter
  },
  data() {
    return {
      value: '',
      showKeyboard: false,
      list:[
        1,2,3,4,5,6,7
      ],
      reg:true
    };
  },
  methods: {

    goDetail() {
      if(this.value.length < 6 || this.value == null  || this.value  == '' ){
       return this.$toast.fail('请输入编码');  
      }
      this.$axios.get('/api/portal/articles/api_obtain/code/'+this.value).then(res => {
        if(res.data.data == ""){
          this.$toast('没有在找到这个编号');      
          this.value = ''
          return     
        }else{
          this.$router.push({
              name: "details",
              query: {
                code: this.value,
              },
          });   
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .container{
    ::v-deep .van-password-input{
        margin-top:45px;
    }
  }
</style>